define("ember-google-publisher-tags/utils/get-viewport-tolerance", ["exports"], function (exports) {
  exports["default"] = getViewportTolerance;

  function getViewportTolerance(adWidth, adHeight, percentVisible) {
    var ratio = 1 / Math.sqrt(1 / percentVisible);

    var newAreaWidth = adWidth * ratio;
    var newAreaHeight = adHeight * ratio;

    var horizontalTolerance = (adWidth - newAreaWidth) / 2;
    var verticalTolerance = (adHeight - newAreaHeight) / 2;

    return {
      top: verticalTolerance,
      bottom: verticalTolerance,
      left: horizontalTolerance,
      right: horizontalTolerance
    };
  }
});