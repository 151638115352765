define('ember-google-publisher-tags/services/ad-queue', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var newArray = _ember['default'].A;
    var on = _ember['default'].on;
    exports['default'] = _ember['default'].Service.extend({
        queue: newArray(),
        tracing: false,

        onInit: on('init', function () {
            this.get('loadGPT').perform(0);
        }),

        push: function push(component) {
            var _this = this;

            if (component.get('tracing')) {
                this.set('tracing', true);
            }

            this.trace('adding: ' + component.get('adId'));

            this.get('queue').pushObject(component.get('elementId'));

            this._pushCmd(function (googletag) {
                var _component$getProperties = component.getProperties('adId', 'width', 'height', 'elementId', 'adSizes');

                var adId = _component$getProperties.adId;
                var width = _component$getProperties.width;
                var height = _component$getProperties.height;
                var elementId = _component$getProperties.elementId;
                var adSizes = _component$getProperties.adSizes;

                _this.trace('defining slot: ' + adId + ' @ ' + width + 'x' + height + ' in ' + elementId);

                adSizes = adSizes || [width, height];

                var slot = googletag.defineSlot(adId, adSizes, elementId).addService(googletag.pubads());
                component.set('slot', slot);
                component.addTargeting();
                var targeting = component.get('targeting');
                Object.keys(targeting).forEach(function (k) {
                    slot.setTargeting(k, targeting[k]);
                });
            });

            this.get('displayAll').perform(1000);
        },

        loadGPT: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(delay) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this2 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return (0, _emberConcurrency.timeout)(delay);

                    case 2:

                        this._pushCmd(function (googletag) {
                            _this2.trace('enableSingleRequest: ', googletag.pubads().enableSingleRequest());
                            _this2.trace('enableServices: ', googletag.enableServices());
                        });

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })).restartable(),

        displayAll: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(delay) {
            var queue, divIds;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this3 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return (0, _emberConcurrency.timeout)(delay);

                    case 2:
                        queue = this.get('queue');
                        divIds = queue.toArray();

                        queue.clear();

                        this._pushCmd(function (googletag) {
                            divIds.forEach(function (id) {
                                _this3.trace('display: ' + id);
                                googletag.display(id);
                            });
                        });

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })).restartable(),

        _pushCmd: function _pushCmd(cmdFunc) {
            var _this4 = this;

            this._wrapErrorHandling(function () {
                var googletag = window.googletag;

                if (googletag && googletag.cmd) {
                    googletag.cmd.push(function () {
                        _this4._wrapErrorHandling(function () {
                            cmdFunc(googletag);
                        });
                    });
                }
            });
        },
        _wrapErrorHandling: function _wrapErrorHandling(func) {
            try {
                func();
            } catch (e) {
                _ember['default'].Logger.error('gpt exception: ', e);
            }
        },

        trace: function trace() {
            if (this.get('tracing')) {
                var _Ember$Logger;

                (_Ember$Logger = _ember['default'].Logger).log.apply(_Ember$Logger, arguments);
            }
        },

        // Refresh ads
        refresh: function refresh(slot, interval) {
            console.log("slot:" + slot + " internval:" + interval);

            googletag.cmd.push(function () {
                googletag.pubads().refresh([gptAdSlots[0]]);
            });
        }
        // Refresh ads
    });
});