define('ember-google-publisher-tags/components/google-publisher-tag', ['exports', 'ember', 'ember-in-viewport', 'ember-google-publisher-tags/utils/get-viewport-tolerance', 'ember-get-config'], function (exports, _ember, _emberInViewport, _emberGooglePublisherTagsUtilsGetViewportTolerance, _emberGetConfig) {
    var _slice = Array.prototype.slice;
    var Component = _ember['default'].Component;
    var assert = _ember['default'].assert;
    var get = _ember['default'].get;
    var set = _ember['default'].set;
    var getProperties = _ember['default'].getProperties;
    var setProperties = _ember['default'].setProperties;
    var observer = _ember['default'].observer;
    var htmlSafe = _ember['default'].String.htmlSafe;
    var log = _ember['default'].Logger.log;
    var later = _ember['default'].run.later;
    var service = _ember['default'].inject.service;

    function safeSet(obj) {
        if (obj && !get(obj, 'isDestroyed') && !get(obj, 'isDestroying')) {
            for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                args[_key - 1] = arguments[_key];
            }

            set.apply(undefined, [obj].concat(args));
        }
    }

    exports['default'] = Component.extend(_emberInViewport['default'], {
        classNames: ['google-publisher-tag'],
        attributeBindings: ['style'],
        refreshCount: 0,
        slot: null,
        adQueue: service(),
        targeting: {},
        iframeUrl: null,

        // user-facing properties
        placement: 0,
        refresh: 0,
        refreshLimit: 0,
        tracing: false,
        shouldWatchViewport: true,
        backgroundRefresh: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            if (_emberGetConfig['default'].gpt && _emberGetConfig['default'].gpt.iframeJail) {
                var rootUrl = _emberGetConfig['default'].gpt.iframeRootUrl || _emberGetConfig['default'].rootURL;
                set(this, 'iframeUrl', rootUrl + 'gpt-iframe.html');
            }

            var _getProperties = getProperties(this, 'adId', 'width', 'height', 'placement');

            var adId = _getProperties.adId;
            var width = _getProperties.width;
            var height = _getProperties.height;
            var placement = _getProperties.placement;

            // width and height are also passed into .defineSlot(), which will
            // silently fail unless they are actually numbers
            assert('google-publisher-tag "' + adId + ' (' + placement + ')": width and height must be numbers', typeof width === 'number' && typeof height === 'number');

            var style = 'width:' + width + 'px; height:' + height + 'px;';
            set(this, 'style', htmlSafe(style));
        },

        didInsertElement: function didInsertElement() {
            this.viewportSetup();
            // we must call viewportSetup() first before calling ._super()
            // where the ember-in-viewport mixin consumes the properties
            this._super.apply(this, arguments);

            this.backgroundSetup();
            this.refreshSetup();
        },

        viewportSetup: function viewportSetup() {
            var _getProperties2 = getProperties(this, 'shouldWatchViewport', 'width', 'height');

            var shouldWatchViewport = _getProperties2.shouldWatchViewport;
            var width = _getProperties2.width;
            var height = _getProperties2.height;

            if (shouldWatchViewport) {
                setProperties(this, {
                    viewportEnabled: shouldWatchViewport,
                    viewportSpy: true,
                    viewportTolerance: (0, _emberGooglePublisherTagsUtilsGetViewportTolerance['default'])(width, height, 0.5)
                });
                set(this, 'inViewport', false);
            } else {
                // since enter/exit hooks won't be fired, `inViewport` will never change. So,
                // we set this to true here now and forever
                set(this, 'inViewport', true);
            }
        },

        backgroundSetup: function backgroundSetup() {
            var _this = this;

            if (get(this, 'backgroundRefresh')) {
                // since we're not going to hook the visibilitychange event, set this manually
                set(this, 'inForeground', true);
            } else {
                set(this, 'inForeground', !document.hidden);
                if (document && document.addEventListener) {
                    document.addEventListener('visibilitychange', function () {
                        _this.trace('visibilitychange: document.hidden: ', document.hidden);
                        safeSet(_this, 'inForeground', !document.hidden);
                    });
                }
            }
        },

        refreshSetup: function refreshSetup() {
            // for setup, we always set this to true to initialize the ad the first time
            set(this, 'isRefreshDue', true);
        },

        // This is the meat of this component: any time one of these 3 properties is .set(), we need
        // to check if a refresh should be triggered. A refresh is triggered ONLY if all 3 properties
        // are true.
        triggerRefresh: observer('inViewport', 'isRefreshDue', 'inForeground', function () {
            var _this2 = this;

            var count = 0;
            ['inViewport', 'isRefreshDue', 'inForeground'].forEach(function (prop) {
                if (get(_this2, prop)) {
                    count++;
                }
            });
            if (count === 3) {
                this.doRefresh();
                this.waitForRefresh();
            } else {
                this.trace('skipping refresh with count ' + count);
            }
        }),

        doRefresh: function doRefresh() {
            var _this3 = this;

            if (!get(this, 'isDestroying') && !get(this, 'isDestroyed')) {
                this.incrementProperty('refreshCount');
            }

            var _getProperties3 = getProperties(this, 'refreshCount', 'refreshLimit');

            var refreshCount = _getProperties3.refreshCount;
            var refreshLimit = _getProperties3.refreshLimit;

            this.trace('refreshing now: ' + refreshCount + ' of ' + refreshLimit);
            this.addTargeting();

            if (get(this, 'iframeUrl')) {
                this.buildIframeJail();
            } else {
                (function () {
                    var slot = get(_this3, 'slot');
                    var googletag = window.googletag;
                    if (slot && googletag && googletag.cmd) {
                        googletag.cmd.push(function () {
                            googletag.pubads().refresh([slot]);
                        });
                    } else {
                        get(_this3, 'adQueue').push(_this3);
                    }
                })();
            }
        },

        buildIframeJail: function buildIframeJail() {
            var _this4 = this;

            var _getProperties4 = getProperties(this, 'iframeUrl', 'width', 'height', 'adId', 'targeting');

            var iframeUrl = _getProperties4.iframeUrl;
            var width = _getProperties4.width;
            var height = _getProperties4.height;
            var adId = _getProperties4.adId;
            var targeting = _getProperties4.targeting;

            var iframeExternalUrl = _emberGetConfig['default'].gpt && _emberGetConfig['default'].gpt.iframeExternalUrl;

            var adParams = { adId: adId, width: width, height: height };
            var targetingParams = Object.keys(targeting).map(function (k) {
                return [k, targeting[k]];
            });

            var finalUrl = iframeUrl;
            if (iframeExternalUrl) {
                finalUrl = iframeExternalUrl + encodeURIComponent(JSON.stringify({
                    ad: adParams,
                    targeting: targetingParams
                }));
            }
            this.$().prepend('<iframe style="display:none; width:' + width + 'px; height:' + height + 'px" frameBorder="0" src="' + finalUrl + '"></iframe>');

            var frames = this.$('iframe');
            var newAd = frames.get(0);
            var existingAds = frames.length > 1 ? frames.slice(1) : this.$([]);

            this.$(newAd).on('load', function () {
                var elementId = get(_this4, 'elementId');
                _this4.trace('passing info to iframe in ' + elementId + ' for ' + adId + ': ', targetingParams);

                if (!iframeExternalUrl) {
                    newAd.contentWindow.ad = adParams;
                    newAd.contentWindow.targeting = targetingParams;
                    newAd.contentWindow.startCallingGpt = true;
                }

                later(_this4, function () {
                    existingAds.each(function (index, node) {
                        $(node).remove();
                    });
                    _this4.$(newAd).show();
                }, 500);
            });
        },

        addTargeting: function addTargeting() {
            // override this in child components, if needed, but an example is:
            // set(this, 'targeting', {
            //     placement: get(this, 'placement'),
            //     refresh_count: get(this, 'refreshCount'),
            //     planet: 'Earth'
            // });
        },

        waitForRefresh: function waitForRefresh() {
            var _this5 = this;

            safeSet(this, 'isRefreshDue', false);

            var refreshInterval = get(this, 'refresh');
            if (!refreshInterval || refreshInterval <= 0) {
                this.trace('refresh is disabled');
                return;
            }

            var _getProperties5 = getProperties(this, 'refreshLimit', 'refreshCount');

            var refreshLimit = _getProperties5.refreshLimit;
            var refreshCount = _getProperties5.refreshCount;

            if (refreshLimit > 0 && refreshCount >= refreshLimit) {
                this.trace('refreshCount has met refreshLimit: ' + refreshLimit);
                return;
            }

            this.trace('waiting for ' + refreshInterval + ' seconds to refresh');
            later(this, function () {
                _this5.trace('refresh is due');
                safeSet(_this5, 'isRefreshDue', true);
            }, 1000 * refreshInterval);
        },

        trace: function trace() {
            if (get(this, 'tracing')) {
                var _getProperties6 = getProperties(this, 'adId', 'placement');

                var adId = _getProperties6.adId;
                var placement = _getProperties6.placement;

                log.apply(undefined, [adId + ' (' + placement + '):'].concat(_slice.call(arguments)));
            }
        },

        didEnterViewport: function didEnterViewport() {
            this.trace('entered viewport');
            safeSet(this, 'inViewport', true);
        },

        didExitViewport: function didExitViewport() {
            this.trace('exited viewport');
            safeSet(this, 'inViewport', false);
        }

    });
});
// for Google Publisher Tag ads
//
// required props:
//    - adId: eg, '/6355419/Travel/Europe/France/Paris'
//    - height: a css size in pixels
//    - width: ditto
//
// See README for optional/user-facing properties.