define("ember-query-params-reset/utils/reset-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resetQueryParams;
  function getQueryParams(route) {
    return Ember.get(route, '_qp.qps');
  }
  function shouldResetQueryParam(queryParamsToReset, qp) {
    return !queryParamsToReset || queryParamsToReset.indexOf(qp.prop) !== -1;
  }
  function resetQueryParam(controller, qp) {
    Ember.set(controller, qp.prop, qp.defaultValue);
    return qp.defaultValue;
  }
  function resetQueryParams(route, optionalArrayOfQueryParams) {
    var controller = Ember.get(route, 'controller');
    var qps = getQueryParams(route);
    var queryParamsToReset = optionalArrayOfQueryParams;
    var defaultValues = {};
    for (var i in qps) {
      if (!Object.hasOwnProperty.call(qps, i)) {
        continue;
      }
      var qp = qps[i];
      if (!shouldResetQueryParam(queryParamsToReset, qp)) {
        continue;
      }
      var defaultValue = resetQueryParam(controller, qp);
      defaultValues[qp.prop] = defaultValue;
    }
    return defaultValues;
  }
});