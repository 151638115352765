define('ui-dropzone/components/default-template', ['exports', 'ember', 'ui-dropzone/templates/components/custom-template'], function (exports, _ember, _uiDropzoneTemplatesComponentsCustomTemplate) {
  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var debug = _ember['default'].debug;
  var isPresent = _ember['default'].isPresent;
  // jshint ignore:line
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var merge = _ember['default'].merge;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line

  exports['default'] = _ember['default'].Component.extend({
    layout: _uiDropzoneTemplatesComponentsCustomTemplate['default'],
    classNames: ['custom-template'],
    classNameBindings: ['type'],
    type: 'preview',
    register: null, // you must pass in yielded register
    zoneId: null, // set during registration

    _register: on('init', function () {
      var _this = this;

      run.schedule('afterRender', function () {
        var _getProperties = _this.getProperties('type', 'register');

        var type = _getProperties.type;
        var register = _getProperties.register;

        var content = _this.$()[0].innerHTML;
        if (typeOf(register) === 'function') {
          var zoneId = register(type, content);
          _this.set('zoneId', zoneId);
        } else {
          debug('The ' + type + ' template failed to register with the dropzone, make sure you are including the "yield as |register|" in your drop-zone component.');
        }
      });
    })
  });
});