define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/can-use-intersection-observer', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _canUseDom, _canUseRaf, _canUseIntersectionObserver, _isInViewport, _checkScrollDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var assign = Ember.assign;
  var Mixin = Ember.Mixin;
  var typeOf = Ember.typeOf;
  var $ = Ember.$;
  var set = Ember.set;
  var get = Ember.get;
  var setProperties = Ember.setProperties;
  var next = Ember.run.next;
  var bind = Ember.run.bind;
  var debounce = Ember.run.debounce;
  var scheduleOnce = Ember.run.scheduleOnce;
  var not = Ember.computed.not;
  var getOwner = Ember.getOwner;


  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};

  exports.default = Mixin.create({
    /**
     * IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    viewportExited: not('viewportEntered').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var options = assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      setProperties(this, options);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!_canUseDom.default) {
        return;
      }

      var viewportEnabled = get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners();
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
      }
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var owner = getOwner(this);

      if (owner) {
        return assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    _startListening: function _startListening() {
      var _this = this;

      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(get(this, 'viewportScrollSensitivity'));

      if (!get(this, 'viewportUseRAF')) {
        get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
              event = listener.event;

          context = get(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },
    _setViewportEntered: function _setViewportEntered() {
      var scrollableArea = get(this, 'scrollableArea') ? document.querySelector(get(this, 'scrollableArea')) : null;

      var element = get(this, 'element');

      if (!element) {
        return;
      }

      if (get(this, 'viewportUseIntersectionObserver')) {
        var _viewportTolerance = this.viewportTolerance,
            _viewportTolerance$to = _viewportTolerance.top,
            top = _viewportTolerance$to === undefined ? 0 : _viewportTolerance$to,
            _viewportTolerance$le = _viewportTolerance.left,
            left = _viewportTolerance$le === undefined ? 0 : _viewportTolerance$le,
            _viewportTolerance$bo = _viewportTolerance.bottom,
            bottom = _viewportTolerance$bo === undefined ? 0 : _viewportTolerance$bo,
            _viewportTolerance$ri = _viewportTolerance.right,
            right = _viewportTolerance$ri === undefined ? 0 : _viewportTolerance$ri;

        var options = {
          root: scrollableArea,
          rootMargin: top + 'px ' + right + 'px ' + bottom + 'px ' + left + 'px',
          threshold: get(this, 'intersectionThreshold')
        };

        this.intersectionObserver = new IntersectionObserver(bind(this, this._onIntersection), options);
        this.intersectionObserver.observe(element);
      } else {
        var $contextEl = scrollableArea ? $(scrollableArea) : $(window);
        var boundingClientRect = element.getBoundingClientRect();

        if (boundingClientRect) {
          this._triggerDidAccessViewport((0, _isInViewport.default)(boundingClientRect, $contextEl.innerHeight(), $contextEl.innerWidth(), get(this, 'viewportTolerance')));
          if (get(this, 'viewportUseRAF')) {
            rAFIDS[get(this, 'elementId')] = window.requestAnimationFrame(bind(this, this._setViewportEntered));
          }
        }
      }
    },


    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection: function _onIntersection(entries) {
      var entry = entries[0];

      if (entry.isIntersecting) {
        set(this, 'viewportEntered', true);
        this.trigger('didEnterViewport');
      } else if (entry.intersectionRatio <= 0) {
        // exiting viewport
        set(this, 'viewportEntered', false);
        this.trigger('didExitViewport');
      }
    },
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var $contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (false && !($contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', $contextEl));
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var elementId = get(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: $contextEl.scrollTop(),
        left: $contextEl.scrollLeft()
      };

      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && get(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var viewportEntered = get(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (get(this, 'viewportSpy') || !viewportEntered) {
        set(this, 'viewportEntered', hasEnteredViewport);
      }

      this.trigger(triggeredEventName);
    },
    _unbindIfEntered: function _unbindIfEntered() {
      if (!get(this, 'viewportSpy') && get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        set(this, 'viewportEntered', true);
      }
    },
    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;

      return scheduleOnce('afterRender', this, function () {
        _this2._setViewportEntered();
      });
    },
    _debouncedEventHandler: function _debouncedEventHandler(methodName) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (false && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEventHandler', methodName));
      (false && !(typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', typeOf(methodName) === 'string'));


      debounce(this, function () {
        return _this3[methodName].apply(_this3, _toConsumableArray(args));
      }, get(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var _this4 = this;

      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var $contextEl = get(this, 'scrollableArea') ? $(get(this, 'scrollableArea')) : $(window);

      $contextEl.on('scroll.directional#' + get(this, 'elementId'), function () {
        _this4._debouncedEventHandler('_triggerDidScrollDirection', $contextEl, sensitivity);
      });
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = get(this, 'elementId');

      var context = get(this, 'scrollableArea') || window;

      $(context).off('scroll.directional#' + elementId);
      delete lastPosition[elementId];
      delete lastDirection[elementId];
    },
    _bindListeners: function _bindListeners() {
      var _this5 = this;

      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (false && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (false && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));


      $(context).on(event + '.' + get(this, 'elementId'), function () {
        _this5._debouncedEventHandler('_setViewportEntered', context);
      });
    },
    _unbindListeners: function _unbindListeners() {
      var _this6 = this;

      var elementId = get(this, 'elementId');

      if (get(this, 'viewportUseRAF')) {
        next(this, function () {
          window.cancelAnimationFrame(rAFIDS[elementId]);
          delete rAFIDS[elementId];
        });
      }

      get(this, 'viewportListeners').forEach(function (listener) {
        var context = listener.context,
            event = listener.event;

        context = get(_this6, 'scrollableArea') ? get(_this6, 'scrollableArea') : context;
        $(context).off(event + '.' + elementId);
      });

      this._unbindScrollDirectionListener();
    }
  });
});