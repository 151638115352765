define("ember-query-params-reset/mixins/query-params-reset-route", ["exports", "ember-query-params-reset/utils/reset-query-params"], function (_exports, _resetQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        (0, _resetQueryParams.default)(this);
      }
      this._super.apply(this, arguments);
    }
  });
});